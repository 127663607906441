import React, { Fragment } from "react";

import { Dropdown, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import Moment from "react-moment";
import Avatar from "react-avatar";

import { useEffect, Suspense, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { da } from "date-fns/locale";

const SearchUsers = (props) => {
  const [show, setShow] = useState(false);
  const [msgT, setMsgT] = useState("Loading");
  const [msg, setMsg] = useState("Loading, please wait...");
  const [userData, setUserData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [page, setPage] = useState(1);
  const [dataCount, setDataCount] = useState(10);

  const [showUserInfo, setShowUserInfo] = useState(false);
  const [user_info, setUserInfo] = useState({});
  useEffect(() => {
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);

    axiosInstance
      .get(
        "/api/admin/user/get/by/like/10/" +
          page +
          "/" +
          props.match.params.search
      )
      .then((rs) => {
        setUserCount(rs.data.data.count);
        setUserData(rs.data.data.rows);
        setDataCount(rs.data.data.rows.length);
        setShow(false);
      });
  }, [props.match]);
  const [formState, setFormState] = useState({ cashout: "", coupon: "" });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormState({ ...formState, [name]: value });
  };

  const handleClose = async (e) => {
    setShow(false);
  };
  const next = async (e) => {
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
    e.preventDefault();
    setUserData([]);
    let nextPage = page + 1;

    axiosInstance
      .get(
        "/api/admin/user/get/by/like/10/" +
          nextPage +
          "/" +
          props.match.params.search
      )
      .then((rs) => {
        setPage(nextPage);
        setUserCount(rs.data.data.count);
        setUserData(rs.data.data.rows);
        setDataCount(rs.data.data.rows.length);
        setShow(false);
      });
  };

  const pre = async (e) => {
    e.preventDefault();
    setUserData([]);
    let nextPage = page - 1;
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
    axiosInstance
      .get(
        "/api/admin/user/get/by/like/10/" +
          nextPage +
          "/" +
          props.match.params.search
      )
      .then((rs) => {
        setPage(nextPage);
        setUserCount(rs.data.data.count);
        setUserData(rs.data.data.rows);
        setDataCount(rs.data.data.rows.length);
        setShow(false);
      });
  };

  const handleUserInfo = (e) => {
    const info = JSON.parse(e.target.getAttribute("url"));
    setUserInfo(info);
    setShowUserInfo(true);
  };
  return (
    <Fragment>
      <>
        {/* <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    
                </Suspense>
			</div> */}
        <Modal show={show} animation={false} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{msgT}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{msg}</Modal.Body>
        </Modal>
        <div className="col-12">
          <div className="row">
            <div className="col-xl-4 col-xxl-4 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-primary text-primary">
                      <img
                        src="/images/symbols/users-relation-svgrepo-com.svg"
                        width="30"
                        height="30"
                      />
                    </span>
                    <div className="media-body">
                      <p className="mb-1">
                        Total <small>(users)</small>
                      </p>
                      <h4 className="mb-0">{userCount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Users</h4>
              <div className="col-md-6">
                <input
                  className="form-control"
                  onChange={handleChange}
                  value={formState.cashout}
                  type="text"
                  name="cashout"
                  id="cashout"
                  placeholder="Search by username, email, firstname & lastname"
                />
                {formState.cashout != "" ? (
                  <Link
                    to={"/search/users/" + formState.cashout}
                    className="btn bg-danger text-white btn-block"
                  >
                    Search
                  </Link>
                ) : (
                  <button
                    type="submit"
                    className="btn bg-danger text-white btn-block"
                  >
                    Search
                  </button>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div id="job_data" className="dataTables_wrapper ">
                  <table
                    className="display w-100 dataTable "
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc">Name</th>
                        <th className="sorting">Email</th>
                        <th className="sorting">Phone</th>
                        <th className="sorting">Active</th>
                        <th className="sorting">Date</th>
                        <th className="sorting">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {userData.map((i, e) => {
                        return (
                          <tr className="even" role="row">
                            <td className="sorting_1">
                              {" "}
                              <Avatar
                                name={i.firstName}
                                size="40"
                                round={true}
                              />{" "}
                              {i.firstName} {i.lastName}
                            </td>
                            <td>{i.email}</td>
                            <td>
                              ({i.countrycode}){i.phone}
                            </td>
                            <td>
                              <span
                                className={
                                  i.isVerified
                                    ? "badge badge-success"
                                    : "badge badge-danger"
                                }
                              >
                                {i.isVerified ? "Active" : "Inactive"}
                              </span>
                            </td>
                            <td>
                              <Moment fromNow ago>
                                {i.createdAt}
                              </Moment>
                            </td>
                            <td>
                              <Dropdown className="dropdown">
                                <Dropdown.Toggle
                                  variant="primary light"
                                  className="i-false sharp"
                                >
                                  <svg
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width={24}
                                        height={24}
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      />
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                  <Dropdown.Item
                                    as={Link}
                                    className="dropdown-item"
                                    to={"/user-activity/" + i.email}
                                  >
                                    User Activity
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    url={JSON.stringify(i)}
                                    onClick={handleUserInfo}
                                  >
                                    User Information
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as={Link}
                                    className="dropdown-item"
                                    to={
                                      "/fort-users/portfolio/" +
                                      i.email +
                                      "/" +
                                      i.username
                                    }
                                  >
                                    Balances
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                           tag={Link}
                            className='dropdown-item'
                            to='/hollox-user/portfolio/'
                          >
                            Ban
                          </Dropdown.Item> */}
                                  <Dropdown.Item
                                    as={Link}
                                    className="dropdown-item"
                                    to={
                                      "/fort-users/wallets/" +
                                      i.email +
                                      "/" +
                                      i.username
                                    }
                                  >
                                    Wallet Addresses
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                            className='dropdown-item'
                            to='/'
                          >
                            Trades
                          </Dropdown.Item> */}
                                  <Dropdown.Item
                                    as={Link}
                                    className="dropdown-item"
                                    to={
                                      "/fort-users-cashout/transactions/" +
                                      i.email +
                                      "/success/" +
                                      i.username
                                    }
                                  >
                                    Cashout
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    as={Link}
                                    className="dropdown-item"
                                    to={
                                      "/fort-users-deposit/transactions/" +
                                      i.email +
                                      "/success/" +
                                      i.username
                                    }
                                  >
                                    Deposit
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    as={Link}
                                    className="dropdown-item"
                                    to={
                                      "/fort-users-internal-transfer/transactions/" +
                                      i.email +
                                      "/success/" +
                                      i.username
                                    }
                                  >
                                    Internal Transfer
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    as={Link}
                                    className="dropdown-item"
                                    to={
                                      "/fort-users-swap/transactions/" +
                                      i.email +
                                      "/success/" +
                                      i.username
                                    }
                                  >
                                    Swap
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      {/* Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries */}
                    </div>
                    {/* onClick={() => activePag.current > 0 && onClick(activePag.current - 1)} */}
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      {page > 1 ? (
                        <Link
                          className="paginate_button previous disabled"
                          onClick={pre}
                        >
                          Previous
                        </Link>
                      ) : (
                        <span></span>
                      )}
                      <span>
                        {/* {paggination.map((number, i) => (
							<Link key={i} to="/table-datatable-basic" className={`paginate_button  ${ activePag.current === i ? "current" : ""} `}onClick={() => onClick(i)}>
								{number}
							</Link>
						))} */}
                      </span>
                      {/* onClick={() => activePag.current + 1 < paggination.length && onClick(activePag.current + 1)} */}

                      {dataCount >= 10 ? (
                        <Link
                          className="paginate_button previous disabled"
                          onClick={next}
                        >
                          Next
                        </Link>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={showUserInfo}
          animation={false}
          onHide={() => setShowUserInfo(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>User Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <img
                    width={250}
                    src={user_info?.image}
                    className="img-fluid"
                  />
                </tr>
                <tr>
                  <td>First Name: </td>
                  <td className="bold-text text-danger">
                    {user_info.firstName} {user_info?.lastName}
                  </td>
                </tr>

                <tr>
                  <td>Date Of Birth: </td>
                  <td className="bold-text text-danger">
                    {user_info?.dateOfBirth}
                  </td>
                </tr>

                <tr>
                  <td>Phone: </td>
                  <td className="bold-text text-danger">{user_info?.phone}</td>
                </tr>
                <tr>
                  <td>Address: </td>
                  <td className="bold-text text-danger">
                    {user_info?.address || "NOT-PROVIDED"}
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </>
    </Fragment>
  );
};

export default SearchUsers;
