import axios from "axios";
import { store } from "../store/store";
const state = store.getState();

export const baseURL = "https://egoras-v3-staging.egoras.com/";
const martgptInstance = axios.create({
  baseURL,
});

martgptInstance.interceptors.request.use((config) => {
  config.params = config.params || {};
  config.headers = config.headers || {};
  return config;
});

export default martgptInstance;
