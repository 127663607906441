import {
  ADD_PRODUCT_ROUTE,
  ALL_PRODUCTS_ROUTE,
  SPECIFIC_PRODUCT_ROUTE,
  UPDATE_PRODUCT_ROUTE,
} from "../constants/apiRoutes";
import martgptInstance from "./martgptInstance";

export const ALL_PRODUCTS = async () => {
  try {
    return (await martgptInstance.get(ALL_PRODUCTS_ROUTE)).data.data
      .getAllUploadedProduct;
  } catch (error) {
    console.log(error, "goodluck special");
    return error;
  }
};

export const SPECIFIC_PRODUCT = async ({ id }) => {
  try {
    return (await martgptInstance.get(`${SPECIFIC_PRODUCT_ROUTE}/${id}`)).data;
  } catch (error) {
    console.log(error, "goodluck special");
    return error;
  }
};

export const ADD_PRODUCT = async (payload) => {
  try {
    return (await martgptInstance.post(`${ADD_PRODUCT_ROUTE}`, payload)).data;
  } catch (error) {
    console.log(error, "goodluck special");
    return error;
  }
};

export const UPDATE_PRODUCT = async ({ data, product_id }) => {
  try {
    return (await martgptInstance.put(`${UPDATE_PRODUCT_ROUTE}`, data)).data;
  } catch (error) {
    console.log(error, "goodluck special");
    return error;
  }
};
