import {
  FETCH_ALL_PURCHASE_PRODUCTS_VERBOSE,
  USER_ACTIVITY_ROUTE,
} from "../constants/apiRoutes";
import axiosInstance from "./AxiosInstance";

export const SHOW_ALL_PURCHASE_TRANSACTIONS = async () => {
  try {
    return axiosInstance.get(`${FETCH_ALL_PURCHASE_PRODUCTS_VERBOSE}`);
  } catch (error) {
    console.log(error, "exception special");
    return error;
  }
};
