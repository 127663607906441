import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
} from "react-bootstrap";
import BackButton from "../../backButton/backButton";
import { useState } from "react";
import axiosInstance from "../../../../services/AxiosInstance";
import { useEffect } from "react";
const Staff = () => {
  const [show, setShow] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [msgT, setMsgT] = useState("Loading");
  const [msg, setMsg] = useState("Loading, please wait...");

  useEffect(() => {
    fetchStaffs();
  }, []);

  const fetchStaffs = async () => {
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
    axiosInstance.get("/api/admin/all/300/0").then((rs) => {
      setStaffs(rs.data.data.rows);
      setShow(false);
      //      axiosInstance.get("/api/admin/permissions").then((pr) => {

      //  // setPermissions(pr.data.data);
      //     setShow(false);
      //   });
    });
  };
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  return (
    <>
      <div className="col-12">
        <BackButton />

        <div className="">
          <Card>
            <Card.Header>
              <Card.Title>Admin Users</Card.Title>
              <Link className="mr-2 btn btn-info" to="/dashboard/staffs/add">
                {" "}
                + New Admin
              </Link>
              <Link
                className="mr-2 btn btn-info"
                to="/dashboard/staffs/manage-permissions"
              >
                Manage Permissions
              </Link>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="width80">
                      <strong>#</strong>
                    </th>
                    <th>
                      <strong>Full name</strong>
                    </th>
                    <th>
                      <strong>Email</strong>
                    </th>
                    <th>
                      <strong>Phone</strong>
                    </th>
                    <th>
                      <strong>Roles</strong>
                    </th>
                    <th>
                      <strong>Last Updated</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {staffs.map((staff, index) => {
                    return (
                      <tr>
                        <td>
                          <strong>{index + 1}</strong>
                        </td>
                        <td>{staff.firstName + ", " + staff.lastName}</td>
                        <td>{staff.email}</td>
                        <td>{staff.phone ?? "N/A"}</td>
                        <td>
                          <Badge variant="warning light">{staff.roleId}</Badge>
                        </td>
                        <td>{staff.updatedAt}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="warning"
                              className="light sharp i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>Edit</Dropdown.Item>
                              <Dropdown.Item>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Staff;
