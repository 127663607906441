import {
  MARK_AS_READ,
  SHOW_ADMIN_NOTIFICATIONS,
  SHOW_SWAP_PRICE_ROUTE,
} from "../constants/apiRoutes";
import axiosInstance from "./AxiosInstance";

export const SHOW_NOTIFICATION = async () => {
  try {
    return axiosInstance.get(SHOW_ADMIN_NOTIFICATIONS);
  } catch (error) {
    console.log(error, "exception special");
    return error;
  }
};
export const MARK_NOTIFICATION_AS_READ = async (id) => {
  try {
    return axiosInstance.post(`${MARK_AS_READ}/${id}`);
  } catch (error) {
    console.log(error.response, "exception special");
    return error;
  }
};
export const GET_SWAP_PRICE = async () => {
  try {
    return axiosInstance.get(`${SHOW_SWAP_PRICE_ROUTE}`);
  } catch (error) {
    console.log(error.response, "exception special");
    return error;
  }
};
