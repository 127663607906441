import React, { useEffect } from "react";
import { GET_SWAP_PRICE } from "../../../../services/general_services";

const Liquidity = () => {
  const ticker_price = async () => {
    const response = await GET_SWAP_PRICE();
    console.log(response);
  };

  useEffect(() => {
    ticker_price();
  }, []);
  const tickers = [
    {
      name: "Egoras Credit",
      symbol: "EGC",
    },
    { name: "Naira", symbol: "NGN" },
  ];
  const handleSelectChange = (e) => {};
  return (
    <div className="min-h-screen">
      <div className="bg-white flex justify-end py-4  px-5">
        <button className="bg-green-300  px-7 text-black py-2">
          + Add Liquidity
        </button>
      </div>
      <div className="bg-gray-100 w-full my-5 min-h-[60vh] px-10 py-10 rounded-md ">
        <div className=" flex items-center gap-5 ">
          <p>Token A</p>

          <div className="">
            <select name="" id="" onChange={handleSelectChange}>
              {tickers.map((ticker) => {
                return (
                  <option value={ticker.symbol}>
                    {`${ticker.name} ${ticker.symbol}`}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className=" flex  gap-5  flex-col w-full bg-white py-2 px-4 rounded-md">
          <p>Token B</p>

          <div className=" ">
            <select name="" id="" onChange={handleSelectChange}>
              {tickers.map((ticker) => {
                return (
                  <option value={ticker.symbol}>
                    {`${ticker.name} ${ticker.symbol}`}
                  </option>
                );
              })}
            </select>

            <input type="text" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Liquidity;
