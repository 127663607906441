import axios from "axios";
import { store } from "../store/store";
const state = store.getState();

export const baseURL = "https://dataseed.egoras.com";
// export const baseURL = "http://localhost:4005";
// export const baseURL = "https://sandbox.egoras.com/";
const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      window.location.href = "/access-denied";
    }
  }
);
axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  config.params = config.params || {};
  // config.params['auth'] = token;
  // config.params['x-token'] = token;
  //console.log(config);
  config.headers = config.headers || {};
  config.headers["x-token"] = token;
  return config;
});

export default axiosInstance;
