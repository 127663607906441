import React from "react";
import Moment from "react-moment";
import "./model.css";

const UserActivityModels = ({ payload }) => {
  const { message, call_type, status, tunnel, user_email } = payload;
  return (
    <div className="userActivity">
      <h6>
        {message || ""} -- <span aria-status={status}>{status}</span>
      </h6>
      <Moment fromNow ago>
        {payload.createdAt}
      </Moment>
    </div>
  );
};
export default UserActivityModels;
