import React, { Fragment } from "react";
import { Dropdown, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

import { useEffect,Suspense,useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { da } from "date-fns/locale";

const Home = () => {
  const [show, setShow] = useState(false);
  const [msgT, setMsgT] = useState("Loading");
  const [msg, setMsg] = useState("Loading, please wait...");
   const [data, setData] = useState([]);
   const [nairaRate, setNairaRate] = useState(0.00);
   const [dollarBalance, setDollarBalance] = useState(0.00);
   const [moneyBalance, setMoneyBalance] = useState({
    egcBalance: 0.00,
    ngnBalance: 0.00,
    ngnLiquidity: 0.00,
    egcLiquidity: 0.00,
    ngn_url: "",
    egc_url: "",
    price: 0, 
    liquidityUsers: []

   });
   const [btcRate, setBTCRate] = useState(0.00);
   useEffect(() => {
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
      axiosInstance.get("/api/admin/portfolio/all").then((rs) => {
        console.log(rs);
         let totalDollarValue = 0.00;
         let egc = 0.00;
         let ngn = 0.00;
         let egc_url = "";
         let ngn_url = "";
         let price = 0;
         let naira = parseFloat(rs.data.data.result[0].cr.split("-")[0].split(",")[0]);
         
         rs.data.data.result.forEach(element => {
          console.log(element.totalValue, element.symbol);
            if(element.symbol == "NGN"){
              ngn = element.totalValue;
              ngn_url = element.image;
              
            }
           
            if(element.symbol == "EGC"){
               egc = element.totalValue;
                egc_url = element.image;
            }

            //totalDollarValue += parseFloat(element.current_price) * parseFloat(element.totalValue);
         });  //const price  = (checkLiquidityBal.tokenA / checkLiquidityBal.tokenB);
         setNairaRate(naira);
         setDollarBalance(totalDollarValue);
         setData(rs.data.data.result);
         setMoneyBalance({...moneyBalance, ngnBalance: ngn, egcBalance: egc, egc_url: egc_url, ngn_url: ngn_url, ngnLiquidity:  rs.data.data.liquidityPoolBalance.rows[0].tokenA, egcLiquidity:  rs.data.data.liquidityPoolBalance.rows[0].tokenB, price: rs.data.data.liquidityPoolBalance.rows[0].tokenA / rs.data.data.liquidityPoolBalance.rows[0].tokenB, liquidityUsers:  rs.data.data.liquidityPool.rows })
         setShow(false);
      });
    }, []); 
    const handleClose = async (e)  => {
      setShow(false);
  }
   return (
      <Fragment>
         <>
         {/* <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    
                </Suspense>
			</div> */}
                      <Modal show={show}  animation={false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{msgT}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
      
      </Modal>
         <div className="col-12">
          <h1>1 EGC = <span className="text-danger"> <NumericFormat  thousandSeparator=","   displayType="text"
 value={parseFloat(moneyBalance.price).toFixed(2)} /> <small>NGN</small></span></h1>
            <div className="row">
            <div className='col-xl-3 col-xxl-3 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body p-4'>
              <div className='media ai-icon'>
                <span className='mr-3 bgl-primary text-primary'>
                  <img  src={moneyBalance.ngn_url}  width='30'
                    height='30' />
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Balance <small>(Naira)</small></p>
                  <h5 className='mb-0'><NumericFormat  thousandSeparator=","   displayType="text"
 value={parseFloat(moneyBalance.ngnBalance).toFixed(2)} />

</h5>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-xxl-3 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body p-4'>
              <div className='media ai-icon'>
                <span className='mr-3 bgl-primary text-primary'>
                  <img  src={moneyBalance.egc_url}  width='30'
                    height='30' />
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Balance <small>(EGC)</small></p>
                  <h5 className='mb-0'>
               
                     <NumericFormat  thousandSeparator=","   displayType="text"
 value={parseFloat(moneyBalance.egcBalance).toFixed(2)} />
                     </h5>
                  
                </div>
              </div>
            </div>
          </div>
        </div>

         <div className='col-xl-3 col-xxl-3 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body p-4'>
              <div className='media ai-icon'>
                <span className='mr-3 bgl-primary text-primary'>
                  <img  src={moneyBalance.ngn_url}  width='30'
                    height='30' />
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Liquidity <small>(Naira)</small></p>
                  <h5 className='mb-0'><NumericFormat  thousandSeparator=","   displayType="text"
 value={parseFloat(moneyBalance.ngnLiquidity).toFixed(2)} />

</h5>
                 
                </div>
              </div>
            </div>
          </div>
        </div>

         <div className='col-xl-3 col-xxl-3 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body p-4'>
              <div className='media ai-icon'>
                <span className='mr-3 bgl-primary text-primary'>
                  <img  src={moneyBalance.egc_url}  width='30'
                    height='30' />
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Liquidity <small>(EGC)</small></p>
                  <h5 className='mb-0'><NumericFormat  thousandSeparator=","   displayType="text"
 value={parseFloat(moneyBalance.egcLiquidity).toFixed(2)} />

</h5>
                 
                </div>
              </div>
            </div>
          </div>
        </div>


        
        {/* <div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body p-4'>
              <div className='media ai-icon'>
                <span className='mr-3 bgl-primary text-primary'>
                <img  src="/images/symbols/bitcoin.png"  width='30'
                    height='30' />
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Balance <small>(BTC)</small></p>
                  <h4 className='mb-0'>
                  <NumericFormat  thousandSeparator=","   displayType="text"
 value={parseFloat(dollarBalance / btcRate).toFixed(2)} />
                    
                  </h4>
                
                </div>
              </div>
            </div>
          </div>
        </div> */}
            </div>
             <div className="card">
        <div className="card-header">
          <h4 className="card-title">Portofolio</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper ">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      Name
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      Amount
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Price
                    </th>
                    <th className="sorting" style={{ width: "46px" }}>
                      Amount $
                    </th>
                   
                  </tr>
                </thead>

                <tbody>
                
              
             
                 {
                  data.map((i,e) => {

                    

                   return (
                     <tr className="even" role="row">
                     <td className="sorting_1"> <img src={i.image} height="40" width="40" /> {i.name} ({i.symbol})</td>
                     <td>
                     <NumericFormat  thousandSeparator=","   displayType="text"
 value= {parseFloat(i.totalValue ?? "0.00")} />
                        
                     </td>
                     <td>
                       
                        <NumericFormat  thousandSeparator=","   displayType="text"
 value=  {parseFloat(i.current_price)} />
                        </td>
                    <td>
                     
                     <NumericFormat  thousandSeparator=","   displayType="text"
 value= {i.symbol == "NGN" ?  (1 / parseFloat(i.current_price)) * parseFloat(i.totalValue ?? "0.00")  : parseFloat(i.totalValue ?? "0.00") * parseFloat(i.current_price)} />
                       
                     </td>
                    
                   </tr>
                   )
                  })
                 }
                </tbody>
                
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  {/* Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries */}
                </div>
                {/* onClick={() => activePag.current > 0 && onClick(activePag.current - 1)} */}
                <div className="dataTables_paginate paging_simple_numbers" id="example5_paginate">
					{/* <Link className="paginate_button previous disabled" to="/table-datatable-basic" >
						Previous
					</Link> */}
					<span>
						{/* {paggination.map((number, i) => (
							<Link key={i} to="/table-datatable-basic" className={`paginate_button  ${ activePag.current === i ? "current" : ""} `}onClick={() => onClick(i)}>
								{number}
							</Link>
						))} */}
					</span>
               {/* onClick={() => activePag.current + 1 < paggination.length && onClick(activePag.current + 1)} */}
					{/* <Link className="paginate_button next" to="/table-datatable-basic" >
						Next
					</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Liquidity Pool</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper ">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      Email
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      Token A (NGN)
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Token B (EGC)
                    </th>
                    <th className="sorting" style={{ width: "46px" }}>
                      created At 
                    </th>
                   
                  </tr>
                </thead>

                <tbody>
                
              
             
                 {
                  moneyBalance.liquidityUsers.map((i,e) => {

                    

                   return (
                     <tr className="even" role="row">
                     <td className="sorting_1">  {i.email} </td>
                     <td>
                     <NumericFormat  thousandSeparator=","   displayType="text"
 value= {parseFloat(i.tokenA ?? "0.00")} />
                        
                     </td>
                     <td>
                       
                        <NumericFormat  thousandSeparator=","   displayType="text"
 value=  {parseFloat(i.tokenB)} />
                        </td>
                    <td>
                     
                     {i.createdAt}
                     </td>
                    
                   </tr>
                   )
                  })
                 }
                </tbody>
                
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  {/* Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries */}
                </div>
                {/* onClick={() => activePag.current > 0 && onClick(activePag.current - 1)} */}
                <div className="dataTables_paginate paging_simple_numbers" id="example5_paginate">
					{/* <Link className="paginate_button previous disabled" to="/table-datatable-basic" >
						Previous
					</Link> */}
					<span>
						{/* {paggination.map((number, i) => (
							<Link key={i} to="/table-datatable-basic" className={`paginate_button  ${ activePag.current === i ? "current" : ""} `}onClick={() => onClick(i)}>
								{number}
							</Link>
						))} */}
					</span>
               {/* onClick={() => activePag.current + 1 < paggination.length && onClick(activePag.current + 1)} */}
					{/* <Link className="paginate_button next" to="/table-datatable-basic" >
						Next
					</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
         </>
      </Fragment>
   );
};

export default Home;
