import React, { useRef } from "react";
import DirectUpload from "./DirectUpload";
import { useState } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import "./styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const AddProduct = () => {
  const [prodName, setProdName] = useState("");
  const [Disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prodSpec, setProdSpec] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [prodState, setProdState] = useState("");
  const [prodCount, setProdCount] = useState(1);
  const [allCategories, setAllCategories] = useState([]);
  const [brandName, setBrandName] = useState("");
  const [prodAmount, setProdAmount] = useState("");
  const [saleAmount, setSaleAmount] = useState();
  const [imageSrc, setImageSrc] = useState("");
  const [imageSrc2, setImageSrc2] = useState("");
  const [imageSrc3, setImageSrc3] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [new_category, setNew_category] = useState("");
  const [activeSaleTab, setActiveSaleTab] = useState("direct");
  const [addCategory, setAddCategory] = useState(false);
  const [inputCount, setInputCount] = useState(1);
  const [formData, setFormData] = useState({
    product_details: "",
  });
  const [isLoading2, setIsLoading2] = useState(false);
  const [prodCondition, setProdCondition] = useState("");
  const fileInputRef = useRef();
  const fileInputRef2 = useRef();
  const fileInputRef3 = useRef();
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleClick2 = () => {
    fileInputRef2.current.click();
  };
  const handleClick3 = () => {
    fileInputRef3.current.click();
  };

  const handleBrandNameChange = (event) => {
    setBrandName(event.target.value);
  };
  const onEditorStateChange = (editorState) => {
    let text = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    console.log(editorState.getCurrentContent());

    setFormData({ ...formData, product_details: text });
    setEditorState(editorState);
  };
  const toggleAddCategory = () => {
    setAddCategory(!addCategory);
  };
  const UploadProduct = async () => {
    setIsLoading(true);
    setDisable(true);
    let img_cms = await handleImgCms();

    console.log(img_cms);
    const formData = new FormData();

    formData.append("product_image", img_cms.image_1);
    formData.append("product_image2", img_cms.image_2);
    formData.append("product_image3", img_cms.image_3);
    formData.append("product_name", prodName);
    formData.append("product_brand", brandName);
    // formData.append("userAddress", account);
    formData.append("productQuantity", prodCount);

    if (activeSaleTab == "direct") {
      formData.append("product_category", new_category);
      // formData.append("product_details", product_details);
      formData.append("prod_spec", prodSpec);
      formData.append("product_state", prodState);
      formData.append("product_amount", prodAmount);
      formData.append("productType", "DIRECT");

      try {
        const res = await axios.post(
          "/product/initialize/add/product/direct",
          formData
          // config
        );
      } catch (err) {
        console.log(err.response);
        setErrorModal(true);
        setErrorMessage(err.response.data.errorMessage);
        setIsLoading(false);
        setDisable(false);
      }
    } else {
      formData.append("product_condition", prodCondition);
      formData.append("amount", saleAmount);
      formData.append("productType", "INDIRECT");

      try {
        const res = await axios.post(
          "/product/initialize/add/product",
          formData
          // config
        );

        // if (res.status === 200) {
        //   sendProductToBlockchain(
        //     res.data.data.product_id,
        //     "INDIRECT",
        //     prodCount
        //   );
        // } else {
        //   setIsLoading(false);
        //   setDisable(false);
        // }
      } catch (err) {
        console.log(err.response);
        console.log(err);
        console.log(err.message);
        if (err.message == "Network Error") {
          setErrorModal(true);
          setErrorMessage(err.message);
          setIsLoading(false);
          setDisable(false);
        } else {
          setErrorModal(true);
          setErrorMessage(err.response.data.errorMessage);
          setIsLoading(false);
          setDisable(false);
        }
      }
    }

    // console.log(
    //   prodName,
    //   file,
    //   file2,
    //   file3,
    //   brandName,
    //   prodAmount,
    //   prodCount,
    //   new_category,
    //   product_details,
    //   prodSpec,
    //   prodState + "sa"
    // );
  };
  const handleImgCms = async () => {
    const formData = new FormData();
    // console.log(activeSaleTab, account, "__UUUUUU");
    const element = document.getElementById("product_image");
    const element2 = document.getElementById("product_image2");
    const element3 = document.getElementById("product_image3");
    const file = element.files[0];
    const file2 = element2.files[0];
    const file3 = element3.files[0];
    formData.append("product_image", file);
    formData.append("product_image2", file2);
    formData.append("product_image3", file3);
    // const res = await CALL_IMG_CMS(formData);
    // // console.log(res);
    // if (res.success == true) {
    //   console.log("okkk");
    //   return res.data;
    // } else {
    //   console.log("Not Ok");
    // }
  };
  const handleProdStateChange = (event) => {
    setProdState(event.target.value);
    console.log(event.target.value);
  };
  const handleCenter2 = (event) => {
    setNew_category(event.target.value || "");
    console.log(event.target.value);
  };
  const handleSaleAmountChange = (event) => {
    if (activeSaleTab == "direct") {
      setProdAmount(event.target.value);
    } else {
      setSaleAmount(event.target.value);
    }
    console.log(event.target.value);
    //console.log(event.target.value);
  };

  const handleProdCountChange = (event) => {
    setProdCount(event.target.value);
  };

  const AddInputCount = () => {
    setInputCount(inputCount + 1);
  };

  const handleProdConditionChange = (event) => {
    setProdCondition(event.target.value);
    console.log(event.target.value);
    //console.log(event.target.value);
  };

  const inputDivs = [];
  for (let i = 0; i < inputCount; i++) {
    inputDivs.push(
      <div className="sell_container_prod_spec_input_divs" key={i}>
        <input
          type="text"
          className="sell_container_prod_spec_input_div1"
          id={`name-${i}`}
          onChange={(event) => handleInputChange(event, i)}
        />
        -
        <input
          type="text"
          className="sell_container_prod_spec_input_div1"
          id={`value-${i}`}
          onChange={(event) => handleInputChange(event, i)}
        />
      </div>
    );
  }

  const handleNameChange = (event) => {
    setProdName(event.target.value);
  };

  const handleInputChange = (event, i) => {
    const inputValues = [];
    for (let j = 0; j < inputCount; j++) {
      const name = document.getElementById(`name-${j}`).value;
      const value = document.getElementById(`value-${j}`).value;
      inputValues.push(`${name}:${value}`);
    }
    //  console.log();
    let concatenatedValues = inputValues.join(",");
    setProdSpec(concatenatedValues);
  };

  const handleRemoveClick2 = () => {
    setImageSrc2("");
  };

  const handleImageSelect3 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImageSrc3(reader.result);
    };

    reader.readAsDataURL(file);
  };
  const handleRemoveClick3 = () => {
    setImageSrc3("");
  };
  const handleRemoveClick = () => {
    setImageSrc("");
  };
  const handleImageSelect2 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImageSrc2(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      console.log(reader.result);
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div>
      <p></p>
      <DirectUpload
        Disable={Disable}
        UploadProduct={UploadProduct}
        isLoading={isLoading}
        onEditorStateChange={onEditorStateChange}
        // handleProdSpecChange={handleProdSpecChange}
        // account={account}
        prodSpec={prodSpec}
        toggleAddCategory={toggleAddCategory}
        handleProdStateChange={handleProdStateChange}
        prodCount={prodCount}
        handleCenter2={handleCenter2}
        allCategories={allCategories}
        editorState={editorState}
        brandName={brandName}
        handleSaleAmountChange={handleSaleAmountChange}
        handleProdCountChange={handleProdCountChange}
        prodAmount={prodAmount}
        imageSrc3={imageSrc3}
        handleRemoveClick3={handleRemoveClick3}
        handleBrandNameChange={handleBrandNameChange}
        fileInputRef3={fileInputRef3}
        handleImageSelect3={handleImageSelect3}
        handleClick3={handleClick3}
        imageSrc2={imageSrc2}
        handleRemoveClick2={handleRemoveClick2}
        handleRemoveClick={handleRemoveClick}
        fileInputRef2={fileInputRef2}
        handleImageSelect2={handleImageSelect2}
        handleClick2={handleClick2}
        handleClick={handleClick}
        imageSrc={imageSrc}
        // generateAI={generateAI}
        isLoading2={isLoading2}
        fileInputRef={fileInputRef}
        handleImageSelect={handleImageSelect}
        handleNameChange={handleNameChange}
        prodName={prodName}
        inputDivs={inputDivs}
        AddInputCount={AddInputCount}
      ></DirectUpload>
    </div>
  );
};

export default AddProduct;
