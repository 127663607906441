import React, { useState } from "react";
import "./styles.css";

const DeclineModal = ({ visibility, onClose, onClick, state, action, id }) => {
  const [selected_option, setOption] = useState("");
  if (!visibility) return null;

  const handleClose = (e) => {
    if (e.target.id !== "master") return;

    onClose();
  };

  const handleOnChange = (e) => {
    setOption(e.target.value);
  };

  return (
    <div
      onClick={handleClose}
      id="master"
      className="backdrop"
      state={state}
      action
    >
      <div className="main-content">
        <h3>Decide Action to take for Users Fund </h3>

        <p>
          Choose an action to perform while declining the Cashout Transaction
        </p>

        <div className="">
          <div className="action-controls">
            <input
              type="radio"
              name="Action"
              value={"SEIZE"}
              id="seize"
              onChange={handleOnChange}
            />

            <label htmlFor="seize">Seize User Funds </label>
          </div>
          <div className="action-controls">
            <input
              type="radio"
              id="reverse"
              name="Action"
              value={"REVERSE"}
              onChange={handleOnChange}
            />
            <label htmlFor="reverse">Reverse Fund back to User </label>
          </div>
        </div>
        <button
          state={state}
          id={id}
          action={action}
          onClick={onClick}
          selected-action={selected_option}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default DeclineModal;
