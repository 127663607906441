import React, { useEffect, useState } from "react";
import {
  onMessageListener,
  requestPermission,
} from "../../../services/firebase";
import { ToastContainer, toast } from "react-toastify";

const NotificationMain = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });

  const runner = async () => {
    const unsubscribe = onMessageListener().then((payload) => {
      console.log(payload);

      new Notification(payload?.notification?.title, {
        // icon: 'demo2.jpg',
        body: payload?.notification?.body,
      });

      //   setNotification({
      //     title: payload?.notification?.title,
      //     body: payload?.notification?.body,
      //   });

      toast.success(
        `${payload?.notification?.title} : ${payload?.notification?.body}`
      );
    });
  };
  useEffect(() => {
    requestPermission();
    runner();

    // return () => {
    //   unsubscribe.catch((err) => console.log("failed by chance ,  , ," + err));
    // };
  }, []);
  return (
    <div>
      <ToastContainer />
    </div>
  );
};

export default NotificationMain;
