import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
//import Home from './components/Dashboard/Home'
//import MyWallet from './components/Dashboard/MyWallet'
//import Invoices from './components/Dashboard/Invoices'
//import CardsCenter from './components/Dashboard/CardsCenter'
//import Transactions from './components/Dashboard/Transactions'
//import TransactionsDetails from './components/Dashboard/TransactionsDetails'

/// Dashboard
import HolloxUsers from "./components/Dashboard/HolloxUsers";
import SendCrypto from "./components/Dashboard/user/transaction/SendCrypto";
import SendCryptoWithFee from "./components/Dashboard/user/transaction/SendCryptoWithFee";
import EmailMarketing from "./components/Dashboard/EmailMarketing";
import HolloxUserPortfolios from "./components/Dashboard/user/portfolio/UserPortfolio";
import HolloxUserWallets from "./components/Dashboard/user/wallet/UserWallet";
import HolloxUserTransactions from "./components/Dashboard/user/transaction/UserTransaction";
import CashoutTransactions from "./components/Dashboard/user/transaction/cashout";
import HolloxBillTransactions from "./components/Dashboard/user/transaction/BillTransaction";
import HolloxCurrency from "./components/Dashboard/user/transaction/Currency";

import Home from "./components/Dashboard/Home";
/// Dashborad NIN
import Pending from "./components/Dashboard/NIN/Pending";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Wallet from "./components/Dashboard/Wallet";
import CoinDetails from "./components/Dashboard/CoinDetails";
import Portofolio from "./components/Dashboard/Portofolio";
import Transactions from "./components/Dashboard/Transactions";
import MarketCapital from "./components/Dashboard/MarketCapital";

import Task from "./components/Dashboard/Task";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import EcomCustomers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import RechartJs from "./components/charts/rechart";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import SparklineChart from "./components/charts/Sparkline";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

/// Widget
import Widget from "./pages/Widget";

/// Table
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";

/// Form
import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
import WizardForm from "./components/Forms/ReduxWizard/Index";
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Todo from "./pages/Todo";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import Rejected from "./components/Dashboard/NIN/Rejected";
import Approved from "./components/Dashboard/NIN/Approved";
import DepositTransactions from "./components/Dashboard/user/transaction/deposit";
import SwapTransactions from "./components/Dashboard/user/transaction/swap";
import InternalTransactions from "./components/Dashboard/user/transaction/Internal";
import UserCashoutTransactions from "./components/Dashboard/user/transaction/usercashout";
import SearchUsers from "./components/Dashboard/SearchUsers";
import UserDepositTransactions from "./components/Dashboard/user/transaction/userdeposit";
import UserInternalTransactions from "./components/Dashboard/user/transaction/userInternal";
import UserSwapTransactions from "./components/Dashboard/user/transaction/userswap";
import ManualPending from "./components/Dashboard/ManualKYC/Pending";
import ManualRejected from "./components/Dashboard/ManualKYC/Rejected";
import ManualApproved from "./components/Dashboard/ManualKYC/Approved";
import HirePurchaseTransactions from "./components/Dashboard/HirePurchase/HirePurchaseTransactions";

//Staff
import Staff from "./components/Dashboard/staff/Staff";
import Add from "./components/Dashboard/staff/Add";
import Purchases from "./components/Dashboard/user/transaction/purchases";
import UserActivity from "./components/Dashboard/UserActivity";
import IntPending from "./components/Dashboard/InternationalKyc/Pending";
import IntRejected from "./components/Dashboard/InternationalKyc/Rejected";
import IntApproved from "./components/Dashboard/InternationalKyc/Approved";
import Liquidity from "./components/Dashboard/Liquidity";
import ManagePermissions from "./components/Dashboard/staff/ManagePermissions";
import AllProducts from "./components/Dashboard/Products/AllProducts";
import AddProduct from "./components/Dashboard/Products/AddProduct";
import UpdateProduct from "./components/Dashboard/Products/UpdateProduct";
import AccessDenied from "./components/Dashboard/AccessDenied";
//end of Staff

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "fort-users", component: HolloxUsers },
    { url: "access-denied", component: AccessDenied },
    { url: "dashboard/liquidity", component: Liquidity },
    { url: "user-activity/:email", component: UserActivity },
    { url: "email-marketing", component: EmailMarketing },
    { url: "currency", component: HolloxCurrency },

    {
      url: "fort-users/portfolio/:email/:username",
      component: HolloxUserPortfolios,
    },
    {
      url: "fort-users/wallets/:email/:username",
      component: HolloxUserWallets,
    },

    {
      url: "fort-users-cashout/transactions/:email/:status/:username",
      component: UserCashoutTransactions,
    },
    {
      url: "fort-users-deposit/transactions/:email/:status/:username",
      component: UserDepositTransactions,
    },
    {
      url: "fort-users-internal-transfer/transactions/:email/:status/:username",
      component: UserInternalTransactions,
    },
    {
      url: "fort-users-swap/transactions/:email/:status/:username",
      component: UserSwapTransactions,
    },

    //products Routes

    {
      url: "dashboard/products/",
      component: AllProducts,
    },
    {
      url: "dashboard/products/new",
      component: AddProduct,
    },
    {
      url: "dashboard/product/update/:id",
      component: UpdateProduct,
    },
    //Staff
    { url: "dashboard/staffs", component: Staff },
    { url: "dashboard/staffs/add", component: Add },
    {
      url: "dashboard/staffs/manage-permissions",
      component: ManagePermissions,
    },
    //end of staff

    {
      url: "fort-users/all/transactions/:status",
      component: CashoutTransactions,
    },
    {
      url: "hire-purchase/all/transactions/:status",
      component: HirePurchaseTransactions,
    },

    {
      url: "fort-users-deposit/all/transactions/:status",
      component: DepositTransactions,
    },
    {
      url: "fort-users-swap/all/transactions/:status",
      component: SwapTransactions,
    },
    {
      url: "fort-users-internal/all/transactions/:status",
      component: InternalTransactions,
    },

    { url: "search/users/:search", component: SearchUsers },

    {
      url: "hollox-users/bill/transactions/:type",
      component: HolloxBillTransactions,
    },
    { url: "dashboard/purchases", component: Purchases },
    { url: "send/crypto/:type", component: SendCrypto },
    { url: "send/crypto/with/fee/:type", component: SendCryptoWithFee },

    { url: "dashboard", component: Home },

    { url: "dashboard/nin/pending", component: Pending },
    { url: "dashboard/nin/rejected", component: Rejected },
    { url: "dashboard/nin/approved", component: Approved },

    // Manual KYC
    { url: "dashboard/manual/kyc/pending", component: ManualPending },
    { url: "dashboard/manual/kyc/rejected", component: ManualRejected },
    { url: "dashboard/manual/kyc/approved", component: ManualApproved },

    // international KYC
    { url: "dashboard/international/kyc/pending", component: IntPending },
    { url: "dashboard/international/kyc/rejected", component: IntRejected },
    { url: "dashboard/international/kyc/approved", component: IntApproved },

    { url: "dashboard-dark", component: DashboardDark },
    { url: "my-wallet", component: Wallet },
    { url: "coin-details", component: CoinDetails },
    { url: "portfolio", component: Portofolio },
    { url: "transactions", component: Transactions },
    { url: "market-capital", component: MarketCapital },
    { url: "task", component: Task },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "post-details", component: PostDetails },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-media-object", component: UiMediaObject },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    { url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: EcomCustomers },

    /// Form

    { url: "form-redux", component: ReduxForm },
    { url: "form-redux-wizard", component: WizardForm },
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },
    { url: "table-filtering", component: FilteringTable },
    { url: "table-sorting", component: SortingTable },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "todo", component: Todo },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
