import React from "react";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import {
  Button,
  Row,
  Col,
  Card,
  Table,
  Badge,
  Modal,
  Dropdown,
} from "react-bootstrap";
import BackButton from "../../backButton/backButton";
import { useEffect } from "react";
import { useState } from "react";
const ManagePermissions = () => {
  const [show, setShow] = useState(false);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [msgT, setMsgT] = useState("Loading");
  const [msg, setMsg] = useState("Loading, please wait...");
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    status: "ACTIVE",
    roleID: "",
  });
  const handleClose = async (e) => {
    setShow(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(formData);
    setFormData({ ...formData, [name]: value });
  };

  const fetchRoles = async () => {
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
    axiosInstance.get("/api/admin/roles/all").then((rs) => {
      setRoles(rs.data.data);
      setShow(false);
      //      axiosInstance.get("/api/admin/permissions").then((pr) => {

      //  // setPermissions(pr.data.data);
      //     setShow(false);
      //   });
    });
  };

  const addAmin = async (e) => {
    try {
      setMsgT("Adding");
      setMsg("please wait...");
      setShow(true);
      e.preventDefault();
      console.log();
      let url = "/api/admin";
      let sendPayLoad = {
        email: formData.email,
        firstName: formData.first_name,
        lastName: formData.last_name,
        roleId: formData.roleID,
        //"profilePic": ""
      };

      let rs = await axiosInstance.post(url, sendPayLoad);
      setFormData({
        email: "",
        phone: "",
        first_name: "",
        last_name: "",
        status: "ACTIVE",
        roleID: "",
      });
      setMsgT("Success");
      setMsg("Admin added successfully!");
    } catch (error) {
      if (typeof error.response !== "undefined") {
        setMsg(error.response.data.errorMessage);
        setMsgT("Error");
      } else {
        setMsg("Network error. Please try again later!");
        setMsgT("Error");
      }
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  return (
    <>
      <Modal show={show} animation={false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{msgT}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
      </Modal>
      <div className="col-12">
        <Card.Title>Manage Permissions</Card.Title>
        <BackButton />
      </div>
    </>
  );
};

export default ManagePermissions;
