import { USER_ACTIVITY_ROUTE } from "../constants/apiRoutes";
import axiosInstance from "./AxiosInstance";

export const GET_USER_ACTIVITY = async (payload) => {
  const { email, page, limit = 10 } = payload;
  try {
    return axiosInstance.get(
      `${USER_ACTIVITY_ROUTE}/${email.toLowerCase()}/${limit}/${page}`
    );
  } catch (error) {
    console.log(error, "exception special");
    return error;
  }
};
