import React, { useEffect, useState } from "react";
import "./styles.css";
import { SHOW_NOTIFICATION } from "../../../services/general_services";

const NotificationModal = ({
  children,
  onClose,
  visibility,
  payload,
  reload,
}) => {
  const [notifications, setNotifications] = useState([]);

  const [loading, setLoading] = useState(true);
  if (!visibility) return null;

  const handleClose = (e) => {
    if (e.target.id !== "master") return;

    onClose();
  };

  return (
    <div className="backdrop" id="master" onClick={handleClose}>
      <div className="main-content">
        <div className="head-section">
          <p className="heading">
            <i className="far fa-bell"></i>
            Unread({payload.length})
          </p>
          <h6 className="" onClick={reload}>
            <i className="far far-rotate">reload</i>
          </h6>
        </div>

        {children}
      </div>
    </div>
  );
};

export default NotificationModal;
