import React from 'react'
import { NavLink } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import {
  Button,
  Row,
  Col,
  Card,
  Table,
  Badge,
  Modal,
  Dropdown
} from "react-bootstrap";
import BackButton from '../../backButton/backButton';
import { useEffect } from 'react';
import { useState } from 'react';
const Add = () => {
 const [show, setShow] = useState(false);
const [roles, setRoles] = useState([]);
const [permissions, setPermissions] = useState([]);
const [msgT, setMsgT] = useState("Loading");
const [msg, setMsg] = useState("Loading, please wait...");
const [formData, setFormData] = useState({ email: "", phone: "", first_name: "", last_name: "", status: "ACTIVE", roleID: "" });
      const handleClose = async (e)  => {
      setShow(false);
  }

    const handleChange = (event) => {

      const name = event.target.name;
      const value = event.target.value;
      console.log(formData);
      setFormData({ ...formData, [name]: value });
    };

    const fetchRoles = async () => {
    setMsgT("Loading");
    setMsg("Fetching data please wait...");
    setShow(true);
    axiosInstance.get("/api/admin/roles/all").then((rs) => {
        
      setRoles(rs.data.data);
       setShow(false);
    //      axiosInstance.get("/api/admin/permissions").then((pr) => {
       
    //  // setPermissions(pr.data.data);
    //     setShow(false);
    //   });
      });

    }

    const addAmin = async (e) => {
    try {
      setMsgT("Adding");
            setMsg("please wait...");
        setShow(true);
        e.preventDefault();
        console.log();
        let url = "/api/admin";
        let sendPayLoad = {
	        "email": formData.email,
            "firstName": formData.first_name,
            "lastName": formData.last_name,
            "roleId": formData.roleID,
            //"profilePic": ""
}

      let rs = await axiosInstance.post(url, sendPayLoad);
     setFormData({ email: "", phone: "", first_name: "", last_name: "", status: "ACTIVE", roleID: "" });
     setMsgT("Success");
     setMsg("Admin added successfully!");
    } catch (error) {
      if(typeof error.response !== "undefined"){
            
        setMsg(error.response.data.errorMessage);
        setMsgT("Error");
       
    }else{
        setMsg("Network error. Please try again later!");
        setMsgT("Error");
    }
    }
  }

    useEffect(() => {
     fetchRoles();
    }, [])
    
    const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  return (
    <>
         <Modal show={show}  animation={false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{msgT}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
      
      </Modal>
<div className="col-12">
     <Card.Title>New Admin</Card.Title>
    <BackButton />
            <div className="">
        <div className="card">
            <div className="card-header">
              
            </div>
            <div className="card-body">
              <form onSubmit={(e) => e.preventDefault()}>
                  <div className='form-row'>
                    <div className='form-group col-md-6'>
                      <label>First Name</label>
                      <input
                       onChange={handleChange}
                        value={formData.first_name}
                        name="first_name"
                        type='text'
                        className='form-control'
                        placeholder='Jone'
                      />
                    </div>
                    <div className='form-group col-md-6'>
                      <label>Last Name</label>
                      <input
                       onChange={handleChange}
                       value={formData.last_name}
                        name="last_name"
                        type='text'
                        className='form-control'
                        placeholder='Doe'
                      />
                    </div>
                    <div className='form-group col-md-6'>
                      <label>Email</label>
                      <input
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                        type='email'
                        className='form-control'
                        placeholder='Email'
                      />
                    </div>
                    <div className='form-group col-md-6'>
                      <label>Phone</label>
                      <input  onChange={handleChange} type='text' className='form-control' value={formData.phone}
                        name="phone" />
                    </div>
                  </div>
                     <div className='form-group mb-0'>
                    <label className='radio-inline mr-3'>
                      <input  onChange={handleChange} type='radio' name='status' checked={formData.status == "ACTIVE" ? true : false}  value="ACTIVE" /> Active
                    </label>
                    <label className='radio-inline mr-3'>
                      <input  onChange={handleChange} type='radio' name='status' checked={formData.status == "INACTIVE" ? true : false} value="INACTIVE"  />  Inactive
                    </label>
                  
                  </div>

                 <div className='form-group mb-0'>
                    <h3>Roles</h3>
                    <hr />
                    {
                        roles.map((role) => {
                                    console.log(role);
                                 return(   <div className='radio'>
                      <label>
                        <input type='radio'  onChange={handleChange} name='roleID' value={role.id} /> {role.name}
                      </label>
                    </div>)
                        })
                    }
                    
                 
                  
                  </div>
                  <button type='submit' className='btn btn-primary' onClick={addAmin}>
                    Add New Admin
                  </button>
                </form>
            </div>
          </div>
</div>     
</div>
    </>
  )
}

export default Add