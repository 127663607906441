import React from "react";
import { Link, useHistory } from "react-router-dom";
const BackButton = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-ite active ">
          <a href="#" onClick={goBack}>
            <i class="fa fa-arrow-left"></i> Back
          </a>
        </li>
      </ol>
    </nav>
  );
};

export default BackButton;
