import React from "react";

const AccessDenied = () => {
  return (
    <div className=" min-h-[500px] bg-red-500 flex font-bold">
      <h1>You do not have appropriate permission to view this page</h1>

      <h5>If you think this is a mistake, please contact dev team.</h5>
      <a className="bg-red-500 " href="/">
        Go back Home
      </a>
    </div>
  );
};

export default AccessDenied;
