export const USER_ACTIVITY_ROUTE = "/api/user-activity";
export const SHOW_ADMIN_NOTIFICATIONS = "/pub/notification/admin";
export const ALL_PRODUCTS_ROUTE = "/product/uploaded";
export const SPECIFIC_PRODUCT_ROUTE = "/product/uploaded/check";
export const ADD_PRODUCT_ROUTE = "/product/initialize/add/product/direct";
// export const UPDATE_PRODUCT_ROUTE = "/product/uploaded/check";
export const UPDATE_PRODUCT_ROUTE = "/product/update/new/product";
export const MARK_AS_READ = "pub/notification/admin/mark-read";
export const FETCH_ALL_PURCHASE_PRODUCTS_VERBOSE =
  "/api/admin/new/transaction/get/all/transactions/purchase";
export const SHOW_SWAP_PRICE_ROUTE = "/api/swap";

export const WS_URL = "ws://localhost:4005";
