import React, { Fragment } from "react";

import { Dropdown, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import Moment from "react-moment";
import Avatar from "react-avatar";

import { useEffect, Suspense, useState } from "react";
import axiosInstance from "../../../../services/AxiosInstance";
import { da } from "date-fns/locale";
import { GET_USER_ACTIVITY } from "../../../../services/userServices";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import UserActivityModels from "../../../view-models/UserActivityModels";

const UserActivity = () => {
  const { email } = useParams();
  const [show, setShow] = useState(false);
  const [msgT, setMsgT] = useState("Loading");
  const [msg, setMsg] = useState("Loading, please wait...");
  const [activity, setActivity] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [page, setPage] = useState(1);
  const [dataCount, setDataCount] = useState(10);

  useEffect(() => {
    GET_USER_ACTIVITY({ email, page }).then((response) => {
      if (response.data.success) {
        setActivity(response.data.data.activity);
        console.log(response);
      }
    });
  }, []);

  const [formState, setFormState] = useState({ cashout: "", coupon: "" });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormState({ ...formState, [name]: value });
  };

  return (
    <Fragment>
      <>
        <div>
          <h3>User Activity Feed</h3>

          {activity.length >= 1 ? (
            activity.map((a, index) => {
              return (
                <div className="">
                  <UserActivityModels payload={a} />
                </div>
              );
            })
          ) : (
            <div>
              <p>No Activity for this user</p>
            </div>
          )}
        </div>
      </>
    </Fragment>
  );
};

export default UserActivity;
