import React, { useState } from "react";
import "./styles.css";

const NotificationModel = ({ payload, onClick }) => {
  const [active, setActive] = useState(false);
  if (!payload) return <p> NULL</p>;

  const { email, notified, tunnel, createdAt } = payload;

  const meta = JSON.parse(payload.meta);

  //render notification according to notification type

  if (meta.type === "deposit") {
    return (
      <div className="" onClick={onClick}>
        <p
          className="notification-write-up accordion "
          onClick={() => setActive(!active)}
        >
          <i className="far far-arrow-down"></i>
          {email} sent {meta.amount}{" "}
        </p>

        {active && (
          <div className="">
            <p>
              {" "}
              {email} sent {meta.amount} {meta.symbol} to {meta.from}
            </p>
          </div>
        )}
      </div>
    );
  }
  if (meta.type === "received") {
    return (
      <div onClick={onClick}>
        <p
          className="notification-write-up accordion"
          onClick={() => setActive(!active)}
        >
          Credit
        </p>

        {active && (
          <div className="notification-body">
            <p>
              {email} sent {meta.amount} {meta.symbol} to {meta.from}
            </p>
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <p className="notification-write-up">{payload.email}</p>
    </div>
  );
};

export default NotificationModel;
