import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAO379khYtTACVv0-eDKsc_afgcQBkEjxM",
  authDomain: "egorasapp.firebaseapp.com",
  projectId: "egorasapp",
  storageBucket: "egorasapp.appspot.com",
  messagingSenderId: "32192064668",
  appId: "1:32192064668:web:12f85978b02f18f10f1159",
  measurementId: "G-YN5ZBCP22Y",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapidKey:
          "BFq8ENYiNkeH8xju_FeaDvMPtfzDP1YVJj7KfBqjdR_4mApelcYKsefKZ2Zqsvu-UdM8Hwo2mBDzyRLuUpPegRw",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("Client token: " + currentToken);
          } else {
            console.log("Failed to generate token");
          }
        })
        .catch((err) => {
          console.log("error occured", err);
        });
    } else {
      console.log("User Permission denied");
    }
  });
};

requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
