import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  // hello world here

  // return axios.post(
  //     `https://dataseed.egoras.com/pub/staff/login`,
  //     postData,
  // );
  return axios.post(`https://dataseed.egoras.com/pub/staff/login`, postData);
  // return axios.post(`http://localhost:4005/pub/staff/login`, postData);
  // return axios.post(`https://sandbox.egoras.com/pub/staff/login`, postData);
}

export function formatError(errorResponse) {
  swal("Oops", errorResponse.errorMessage, "error");
  // switch (errorResponse.errorMessage) {
  //     case 'EMAIL_EXISTS':
  //         //return 'Email already exists';
  //         swal("Oops", "Email already exists", "error");
  //         break;
  //     case 'EMAIL_NOT_FOUND':
  //         //return 'Email not found';
  //        swal("Oops", "Email not found", "error",{ button: "Try Again!",});
  //        break;
  //     case 'INVALID_PASSWORD':
  //         //return 'Invalid Password';
  //         swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
  //         break;
  //     case 'USER_DISABLED':
  //         return 'User Disabled';

  //     default:

  //         return '';
  // }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  console.log(tokenDetails);
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, history);
}
