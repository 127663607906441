import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import * as Ably from "ably";
import { AblyProvider, useChannel, usePresence } from "ably/react";

const client = new Ably.Realtime(
  "_ZaZIA.zrkh6g:Olv__ikkLdWpwbAxH-pK5mM5_fbyP7gRVRbqouGO6S4"
);
ReactDOM.render(
  <React.StrictMode>
    <AblyProvider client={client}>
      <Provider store={store}>
        <SimpleReactLightbox>
          <BrowserRouter basename="/">
            <ThemeContext>
              <App />
            </ThemeContext>
            {/*   <App /> */}
          </BrowserRouter>
        </SimpleReactLightbox>
      </Provider>
    </AblyProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
