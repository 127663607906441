import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";

//import sound

import sound1 from "../../../assets/sound/mixkit-bell-gentle-alarm-notification-940.wav";
import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/profile/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import NotificationModal from "../../components/Notification/NotificationModal";
import {
  MARK_NOTIFICATION_AS_READ,
  SHOW_NOTIFICATION,
} from "../../../services/general_services";
import NotificationModel from "../../components/Notification/NotificationModel";
import { WS_URL } from "../../../constants/apiRoutes";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useChannel } from "ably/react";

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

  const [notifications, setNotifications] = useState([]);
  //added comment here

  const { channel } = useChannel("admin-notification", (message) => {
    console.log(message);
    //check the content of the message
    if (message.name === "new-notification") {
      const newNotification = message.data;

      console.log(newNotification.notified, "llllllllll");

      let newObj = message.data;

      newObj.meta = JSON.stringify(newObj.meta);

      const sound = new Audio(sound1);
      // sound.play();

      const newArr = [message.data, ...notifications];
      const meta = JSON.parse(message.data?.meta);

      console.log(newArr);
      const message_construct = `${message.data?.email}  ${meta.type} ${meta?.amount} ${meta?.symbol} from ${meta.from}`;
      new Notification(message.data?.email, {
        body: message_construct,
        vibrate: true,
        silent: false,
      });
      setNotifications(newArr);
    }

    // notifications.push(message.data);
  });

  const [darkMode, setDarkMode] = useState(false);

  const [notificationModal, setNotificationModal] = useState(false);

  const body = document.querySelector("body");
  if (darkMode) {
    body.setAttribute("data-theme-version", "dark");
  } else {
    body.setAttribute("data-theme-version", "light");
  }

  const [loading, setLoading] = useState(true);
  const fetchNotifications = async () => {
    setLoading(true);

    const response = await SHOW_NOTIFICATION();

    setLoading(false);
    console.log(response.data.data.rows);

    if (response.success === false) {
      return;
    }

    setNotifications(response.data.data.rows);
  };
  const markAsRead = async (id) => {
    const response = await MARK_NOTIFICATION_AS_READ(id);

    console.log(response, "malaika");

    return response;
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ")}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <li className="nav-item dropdown notification_dropdown">
                <Link
                  to={"#"}
                  className={`nav-link bell dz-theme-mode ${
                    darkMode ? "active" : ""
                  }`}
                >
                  <i
                    className="far fa-sun i-dark"
                    onClick={() => setDarkMode(!darkMode)}
                  ></i>
                  <i
                    className="far fa-moon  i-light"
                    onClick={() => setDarkMode(!darkMode)}
                  ></i>
                </Link>
              </li>
              <li
                className="nav-item dropdown notification_dropdown"
                onClick={() => setNotificationModal(!notificationModal)}
              >
                <Link to={"#"}>
                  <i className="far fa-bell"></i>

                  <span> {notifications.length}</span>
                </Link>
              </li>

              <Dropdown className="nav-item dropdown header-profile ml-sm-4 ml-2">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  variant=""
                  className="nav-link i-false c-pointer"
                >
                  <div className="header-info">
                    <span className="text-black">
                      Hello,{" "}
                      <strong>
                        {
                          JSON.parse(localStorage.getItem("userDetails"))[
                            "meta"
                          ]["user"]["firstName"]
                        }
                      </strong>
                    </span>
                    <p className="fs-12 mb-0">--</p>
                  </div>
                  {/* <img src={} width={20} alt="" /> */}
                  <Avatar
                    name={
                      JSON.parse(localStorage.getItem("userDetails"))["meta"][
                        "user"
                      ]["firstName"]
                    }
                    size={80}
                    textSizeRatio={9}
                    round="20px"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                  {/* <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link> */}
                  {/* <Link to="/email-inbox" className="dropdown-item ai-icon">
                    <svg
                      id="icon-inbox"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                      <polyline points="22,6 12,13 2,6" />
                    </svg>
                    <span className="ml-2">Inbox </span>
                  </Link> */}
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>

      <NotificationModal
        visibility={notificationModal}
        onClose={() => setNotificationModal(false)}
        payload={notifications}
        reload={fetchNotifications}
      >
        {notifications.map((data, index) => (
          <NotificationModel
            payload={data}
            key={index}
            onClick={async () => {
              const result = await markAsRead(data.id);
            }}
          />
        ))}
      </NotificationModal>
    </div>
  );
};

export default Header;
